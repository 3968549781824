import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPreview } from 'gatsby-source-prismic';

import OnImagesLoaded from 'react-on-images-loaded';
import Overlay from '../components/shared/overlay';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Wheel from '../components/beers/wheel';
import Divide from '../components/shared/divide';

const StyledDiv = styled.div`
	margin-top: -1rem;
`;

export const query = graphql`
	query {
		prismicBeersPage {
			prismicId
			data {
				beer_wheel {
					beer {
						uid
						document {
							__typename
							... on PrismicBeer {
								data {
									name {
										text
									}
									type {
										text
									}
									beer_wheel_can_image {
										url
									}
									beer_wheel_background_image {
										url
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => {
	const [isLoaded, setIsLoaded] = useState(true);

	const beers = data.prismicBeersPage.data.beer_wheel.map((b) => ({
		slug: `/beers/${b.beer.uid}`,
		name: b.beer.document.data.name.text,
		type: b.beer.document.data.type.text,
		can: b.beer.document.data.beer_wheel_can_image.url,
		bg: b.beer.document.data.beer_wheel_background_image.url,
	}));

	return (
		<Layout>
			<SEO title="Beers" />
			<Overlay isShown={isLoaded} />
			<OnImagesLoaded
				onLoaded={() => setIsLoaded(false)}
				onTimeout={() => setIsLoaded(false)}
				timeout={10000}
			>
				<Wheel beers={beers} />
			</OnImagesLoaded>
			<StyledDiv>
				<Divide />
			</StyledDiv>
		</Layout>
	);
};

export default withPreview(PageTemplate);
